import AccountTreeIcon from '@material-ui/icons/AccountTree';
import MonitorOverviewIcon from '@material-ui/icons/Terrain';
import MonitorDetailIcon from '@material-ui/icons/ImageSearch';
import { ServiceContracts } from './pages/serviceContracts/ServiceContracts';

const env = process.env.REACT_APP_ENV;

export const isReach = (_, profile) =>
  profile?.email.includes('@reachplc.com') || profile?.email.includes('@trinitymirror.com');

const appsByEnv = {
  development: [
    {
      title: 'Service Contracts',
      permissionCheck: isReach,
      path: '/service-api-contracts',
      wildcard: true,
      icon: <AccountTreeIcon />,
      mainComponent: ServiceContracts,
    },
    {
      title: 'Newsletter and Mailing List Application',
      path: 'https://ml-web.dev.data-services.tm-awx.com/',
      icon: <MonitorOverviewIcon />,
    },
    {
      title: 'Bulk Import Tool',
      path: 'https://import-tool.dev.data-services.tm-awx.com/',
      icon: <MonitorOverviewIcon />,
    },
    {
      title: 'Data Services Monitoring',
      path: 'https://monitoring.dev.data-services.tm-awx.com/',
      icon: <MonitorDetailIcon />,
    },
  ],
  production: [
    {
      title: 'Service Contracts',
      permissionCheck: isReach,
      path: '/service-api-contracts',
      wildcard: true,
      icon: <AccountTreeIcon />,
      mainComponent: ServiceContracts,
    },
    {
      title: 'Newsletter and Mailing List Application',
      path: 'https://ml-web.data-services.tm-awx.com/',
      icon: <MonitorOverviewIcon />,
    },
    {
      title: 'Bulk Import Tool',
      path: 'https://import-tool.data-services.tm-awx.com/',
      icon: <MonitorOverviewIcon />,
    },
    {
      title: 'Data Services Monitoring',
      path: 'https://monitoring.data-services.tm-awx.com/',
      icon: <MonitorDetailIcon />,
    },
  ],
};

const apps = appsByEnv[env];

export default apps;
