import { Link } from 'react-router-dom';
import { cloneElement } from 'react';
import { Typography, Grid, Card, CardActionArea, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LabelIcon from '@material-ui/icons/Label';
import useAuthState from 'auth/useAuthState';
import apps from 'apps/apps';

const useStylesOption = makeStyles(theme => ({
  card: {
    minHeight: '200px',
  },
  title: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    float: 'left',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(0.5),
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
}));

/**
 * If the path is an external reference then return the <a href> link else
 * use the react router to Link to a local component.
 *
 * @param {string} path
 * @returns
 */
const getComponentLink = path => {
  if (path.startsWith('http')) {
    return {
      component: 'a',
      href: path,
    };
  }

  return {
    component: Link,
    to: path,
  };
};

const Option = ({ path, title, description, icon = <LabelIcon /> }) => {
  const classes = useStylesOption();

  const iconProps = { className: classes.icon };
  const iconElement = cloneElement(icon, iconProps);

  return (
    <Card className={classes.card}>
      <CardActionArea {...getComponentLink(path)}>
        <CardContent className={classes.title}>{iconElement}</CardContent>
        <CardContent>
          <br />
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export const WelcomeBanner = () => (
  <>
    <Grid container spacing={10}>
      <Grid item md={12}>
        <Typography variant="h4">Welcome to the {process.env.REACT_APP_PRODUCT_NAME}</Typography>
        <Typography>
          This is the area where you can explore the Data Services through various apps managed and
          maintained byt the Data Service Team.
        </Typography>
      </Grid>
    </Grid>
  </>
);

export const WelcomePane = () => {
  const [authState] = useAuthState();

  const filteredApps = authState.authorised
    ? apps.filter(app => !app.permissionCheck || app.permissionCheck(app, authState.claims))
    : [];

  return (
    <>
      <Grid container spacing={10}>
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          <Grid container spacing={5}>
            {filteredApps.map(app => (
              <Grid key={app.path} item md={3}>
                <Option {...app} />
              </Grid>
            ))}

            {filteredApps.length === 0 && (
              <Typography>
                It looks like you do not have access to any apps at the moment.
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item md={2}></Grid>
      </Grid>
    </>
  );
};
