import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Typography, CssBaseline, Grid, CircularProgress } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import GoogleSSOAuth from 'auth/GoogleSSOAuth';

import { WelcomeBanner, WelcomePane } from './apps/Welcome';
import ReachAppBar from './layout/AppBar';
import ReachTheme from './layout/Theme';

import appRoutes from './apps/pageRoutes';

const Layout = ({ children }) => (
  <>
    <Grid item md={12}></Grid>
    <Grid item md={1}></Grid>
    <Grid item md={10}>
      <Grid item>{children}</Grid>
    </Grid>
    <Grid item md={1}></Grid>
  </>
);

function App() {
  return (
    <ThemeProvider theme={ReachTheme}>
      <CssBaseline />
      <Router>
        <GoogleSSOAuth
          authorised={({ profile }) => [
            <ReachAppBar />,
            <Grid container spacing={2}>
              <Switch>
                <Route path="/" exact>
                  <Layout>
                    <WelcomeBanner />
                    <WelcomePane />
                  </Layout>
                </Route>
                {appRoutes(profile)}
              </Switch>
            </Grid>,
          ]}
          unauthorised={({ profile }) => [
            <ReachAppBar />,
            <Layout>
              <div>I'm sorry {profile && profile.given_name}, I can't let you do that.</div>
            </Layout>,
          ]}
          checking={() => [
            <ReachAppBar />,
            <Typography paragraph>Please wait, checking sign in state...</Typography>,
            <CircularProgress />,
          ]}
        />
      </Router>
    </ThemeProvider>
  );
}

export default App;
