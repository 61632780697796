import SwaggerUI from 'swagger-ui-react';
import { useState } from 'react';
import useAuthState from 'auth/useAuthState';
import 'swagger-ui-react/swagger-ui.css';
import './swagger.css';

import { Button, makeStyles } from '@material-ui/core';

const env = process.env.REACT_APP_ENV;

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const ShowDoc = ({ mode, spec }) => {
  const [authState] = useAuthState();

  return (
    <SwaggerUI
      url={spec}
      onComplete={swaggerUi => {
        swaggerUi.preauthorizeApiKey('bearerAuth', authState.jwt);
        swaggerUi.preauthorizeApiKey('apiKey', 'test');
        swaggerUi.preauthorizeApiKey('accessToken', 'test');
      }}
    />
  );
};

const servicesByEnv = {
  development: [
    {
      name: 'Subscription Status',
      location: 'https://status.dev.data-services.tm-awx.com/__schema',
    },
    {
      name: 'Mailing List Management',
      location: 'https://mailinglistapi-dev.dev.data-services.tm-awx.com/',
    },
  ],

  production: [
    {
      name: 'Subscription Status',
      location: 'https://status.data-services.tm-awx.com/__schema',
    },
    {
      name: 'Mailing List Management',
      location: 'https://mailinglistapi.data-services.tm-awx.com/',
    },
  ],
};

const services = servicesByEnv[env];

export const Menu = ({ spec, setSpec }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      Services:
      {services.map(service => (
        <Button
          variant="contained"
          color={spec === service.location ? 'secondary' : 'primary'}
          onClick={() => setSpec(service.location)}
        >
          {service.name}
        </Button>
      ))}
    </div>
  );
};

export const ServiceContracts = () => {
  const [spec, setSpec] = useState(services[0].location);

  return [<Menu spec={spec} setSpec={setSpec} />, <ShowDoc key="doc" mode="swagger" spec={spec} />];
};
